import React from "react";
import { Link } from "gatsby";
import Layout from "../components/common/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <main
        css={`
          padding: 60px 0 100px 0;
          text-align: center;
        `}
      >
        Page Not Found.{" "}
        <Link
          css={`
            text-decoration: underline;
          `}
          to="/"
        >
          Go Home
        </Link>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
